<template>
 <v-container
    id="logs"
    fluid
    tag="section"
  >
  <v-card
      outlined
      round
      class="ma-4"
      >
      <v-card-text>
        <v-row>
          <v-col
          cols="12"
          md="8"
          >
          <v-autocomplete
            :items="products"
            item-text="title"
            return-object
            v-model="selected_item"
            prepend-icon="mdi-magnify"
            label="Block item from fulfillment"
            clearable
            ></v-autocomplete>
            <v-text-field
              v-if="selected_item"
              outlined
              label="For what reason is this item being blocked?"
              :rules="[v => v && v.length > 5 || 'Required']"
              v-model="reason"
              />
          </v-col>
          <v-col 
            class="my-auto"
            cols="12"
            md="4"
            >
            <v-btn
              color="primary"
              @click="removeItem({ override_default: false })"
              :loading="blocking_item"
              :disabled="!selected_item || !reason"
              >
              Block item
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  <template v-if="!loading || logs.length">
    <v-select
      multiple
      label="Filter logs"
      item-text="key"
      item-value="id"
      v-model="selected_filters"
      :items="filters"
      class="mx-4"
      outlined
      round
      background-color="#ffffff"
      />
      <LogCard 
        v-for="(log, index) in filtered_logs"
        :key="`log-${index}`"
        :log="log"
        />
      <p v-if="!filtered_logs.length" class="ma-4">No logs available</p>
  </template>
  <template v-else>
    <div class="text-center">
    <v-progress-circular
      indeterminate
      :size="50"
      color="primary"
      class="mt-12 mx-auto"
      ></v-progress-circular>
  </div>
  </template>

</v-container>

</template>
<script>
import LogCard from '@/components/LogCard'
import { mapState } from 'vuex'

export default {
  components: {
    LogCard
  },
  computed: {
    ...mapState({
      logs: state => state.warehouse.logs,
      data_sources: state => state.warehouse.company.data_sources,
      products: state => state.product.products.filter(a => !state.warehouse.company.data_sources.reduce((tot, ds) => !ds.blocked_items ? tot : tot.concat(ds.blocked_items), []).some(b => b.item_uuid === a.uuid)),
    }),
    filtered_logs(){
      const logs = this.logs.concat(this.data_sources.reduce((tot, ds) => 
        tot
          .concat(ds.blocked_items.filter(i => i.message))
          .concat(ds.blocked_orders.filter(i => i.message))
      ,[]))
      logs.sort((a,b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
      return this.selected_filters.length ? logs.filter(log => this.selected_filters.some(i => i === log.type)) : logs
    }
  },
  
  
  filters: {
    
  },
  data() {
    return {
      filters: [
        {
          key: 'Order blocks',
          id: 'blocked_order',
        },
        {
          key: 'Item blocks',
          id: 'blocked_item',
        },
        {
          key: 'Inventory adjustments',
          id: 'inventory_change',
        },
        {
          key: 'Inventory confirmations',
          id: 'inventory_confirmation',
        },
      ],
      selected_filters: [],
      loading: true,
      loading_connection: false,
      code: '',
      blocking_item: false,
      selected_item: null,
      reason: 'Out of stock',
    }
  },
  methods: {
    async removeItem(){
      this.blocking_item = true
      const item = {
        uuid: this.selected_item.uuid,
        name: this.selected_item.title,
      }
      const data_source_id = item.uuid.split(':')[1]
      await this.$store.dispatch('order/block_item', { item, data_source_id, reason: this.reason })
      await this.$store.dispatch('warehouse/retrieve_logs')
      this.blocking_item = false
      this.reason = 'Out of stock'
      this.selected_item = null
    },
    filter_logs(t){
      console.log(t)
    }
  },
  async mounted(){
    await this.$store.dispatch('warehouse/retrieve_logs')
    this.loading = false
  }
}
</script>
<style lang="scss" scoped>
    
</style>