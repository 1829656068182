<template>
    <v-card
      outlined
      round
      class="ma-4"
      :class="is_unread ? 'primary lighten-4' : ''"
      >
      <v-card-text>
        <v-row>
          <v-col
          cols="12"
          md="8"
          >
            {{ log.created_at | DateFilter }}
            <p class="text-body text--primary">
              {{ log.message }}
            </p>
          </v-col>
          <v-col 
            class="my-auto"
            cols="12"
            md="4"
            v-if="log.type && (log.type === 'blocked_item' || log.type === 'blocked_order')"
            >
            <v-btn
              color="primary"
              @click="remove_block"
              :loading="loading_action"
              >
              Remove block
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
</template>
<script>

import { DateFilter } from '@/utils/filters'
import { mapState } from 'vuex'

export default {
  filters: {
    DateFilter,
  },
  props: {
    log: {
      type: Object,
      required: true
    }
  },
  components: {
    
  },
  computed: {
    ...mapState({
      read_logs: state => state.user.userProfile.companies.filter(company => company.id === state.warehouse.current_company_id)[0].logs_read_at
    }),
    is_unread(){
      console.log(this.read_logs);
        return new Date(this.read_logs).getTime() < new Date(this.log.created_at).getTime()
    }
  },
  data() {
    return {
      loading: false,
      loading_action: false,
    }
  },
  methods: {
    async remove_block(){
      this.loading_action = true
      await this.$store.dispatch('warehouse/perform_log_action', { log: this.log })
      this.loading_action = false
    },
  },
  mounted(){
    
  }
}
</script>
<style lang="scss" scoped>
    // .new-log {
    //   background-color: rgba(255, 153, 51, 0.3) !important ;
    // }
</style>